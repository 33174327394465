import { Component } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ContactMeDataService } from './services/contactme-data.service';
import { ContactMeForm } from './models/ContactMeForm';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Business } from './models/Business';

@Component({
  selector: 'app-root',
  templateUrl: './contactme.component.html',
  styleUrls: ['./contactme.component.scss']
})
export class ContactMeComponent {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly contactMeDataService: ContactMeDataService,
    private readonly translationService: TranslateService,
    private readonly cookieService: CookieService,
    private readonly router: Router) { }

  public currentYear = String(new Date().getFullYear());
  public options = Options;

  public contactMeForm = this.formBuilder.group({
    contact: this.formBuilder.group({
      fullName: this.formBuilder.control('', [Validators.required]),
      email: this.formBuilder.control('', [Validators.required, ValidateEmail]),
      phoneNumber: this.formBuilder.control('', [Validators.required]),
      mobilePhoneNumber: this.formBuilder.control('')
    }),
    business: this.formBuilder.group({
      companyName: this.formBuilder.control('', [Validators.required]),
      address: this.formBuilder.control('', [Validators.required]),
      city: this.formBuilder.control('', [Validators.required]),
      state: this.formBuilder.control(''),
      postalCode: this.formBuilder.control(''),
      country: this.formBuilder.control('', [Validators.required]),
      displayCountry: this.formBuilder.control(''),
      migrationInterest: this.formBuilder.control(''),
      backupInterest: this.formBuilder.control(''),
      cloudManagerInterest: this.formBuilder.control(''),
      emailMigrationRange: this.formBuilder.control(0),
      emailEnvironment: this.formBuilder.control(''),
      backupUserRange: this.formBuilder.control(0)
    }, { validator: ValidateInterest }),
    termsAndCondtions: this.formBuilder.control('', [Validators.required])
  });

  public fullNameError = { valid: false, message: '' };
  public emailError = { valid: false, message: '' };
  public phoneNumberError = { valid: false, message: '' };
  public companyNameError = { valid: false, message: '' };
  public addressError = { valid: false, message: '' };

  public cityError = { valid: false, message: '' };
  public countryError = { valid: false, message: '' };

  public interestError = { valid: false, message: '' };
  public migrationInterestError = { valid: false, message: '' };
  public backupInterestError = { valid: false, message: '' };

  public emailMigrationRangeError = { valid: false, message: '' };
  public emailEnvironmentError = { valid: false, message: '' };
  public backupUserRangeError = { valid: false, message: '' };
  public termsAndConditionsError = { valid: false, message: '' };

  public showValidationErrors = false;
  public showPlatformError = false;
  public btnDisabled = false;

  onSubmit() {
    this.btnDisabled = true;
    this.showPlatformError = false;

    const contactMeFormData: ContactMeForm = this.contactMeForm.getRawValue();

    if (this.contactMeForm.valid) {
      this.showValidationErrors = false;

      try {
        var request = this.contactMeDataService.ConvertContactMeForm(contactMeFormData);
        this.contactMeDataService.saveContactMe(request).subscribe(() => {
          this.router.navigate(['contact-thankyou']);
        }, (error) => {
          console.error(error);
          this.btnDisabled = false;
          this.setEverythingValid();
          this.showValidationErrors = true;
          this.showPlatformError = true;
        });
      } catch (ex) {
        console.error(ex);
        this.btnDisabled = false;
      }
    }
    else
    {
      this.showValidationErrors = true;
      this.validatePage();
    }
  }

  setEverythingValid() {
    this.fullNameError = { valid: true, message: '' };
    this.emailError = { valid: true, message: '' };
    this.phoneNumberError = { valid: true, message: '' };
    this.companyNameError = { valid: true, message: '' };
    this.addressError = { valid: true, message: '' };

    this.cityError = { valid: true, message: '' };
    this.countryError = { valid: true, message: '' };

    this.interestError = { valid: true, message: '' };
    this.emailMigrationRangeError = { valid: true, message: '' };
    this.emailEnvironmentError = { valid: true, message: '' };
    this.backupUserRangeError = { valid: true, message: '' };
    this.termsAndConditionsError = { valid: true, message: '' };
    this.btnDisabled = false;
  }
  validatePage() {
    this.fullNameError = this.FullNameIsValid();
    this.emailError = this.EmailIsValid();
    this.phoneNumberError = this.PhoneIsValid();
    this.companyNameError = this.BusinessNameIsValid();
    this.addressError = this.AddressIsValid();

    this.cityError = this.CityIsValid();
    this.countryError = this.CountryIsValid();

    this.interestError = this.InterestIsSelected();
    this.emailMigrationRangeError = this.MigrationMailboxRangeIsValid();
    this.emailEnvironmentError = this.EmailEnvironmentIsValid();
    this.backupUserRangeError = this.BackupUserRangeIsValid();
    this.termsAndConditionsError = this.TermsAndConditionsSelected();
    this.btnDisabled = false;
  }

  closePlatformError() { 
    this.showPlatformError = false;
  }

  changeLanguage(languageCode: string) {
    var expirationDate = new Date();
    this.cookieService.set('skLanguageCode', languageCode, new Date(expirationDate.setFullYear(expirationDate.getFullYear() + 10)), "/", null);
    window.location.reload();
  }

  public FullNameIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('contact.fullName');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.NAME_REQUIRED") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public EmailIsValid(): { valid: boolean, message: string } {
    var control = this.contactMeForm.get('contact.email');
    var emailValidation = ValidateEmail(control);
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.EMAIL_REQUIRED") };
      }
    }
    catch
    {

    }
    try { if (emailValidation.InvalidEmail) {
      return { valid: false, message: this.translationService.instant("ERRORS.EMAIL_NOT_VALID") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public PhoneIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('contact.phoneNumber');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.PHONE_REQUIRED") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public BusinessNameIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.companyName');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.BUSINESS_NAME_REQUIRED") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public AddressIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.address');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.ADDRESS_REQUIRED") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public CityIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.city');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.CITY_REQUIRED") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public CountryIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.country');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.COUNTRY_REQUIRED") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public MigrationMailboxRangeIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.emailMigrationRange');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (control.value == 0 || requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.ZERO_NOT_VALID") };
      }
    }
    catch
    {
    }

    return { valid: true, message: ""};
  }

  public EmailEnvironmentIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.emailEnvironment');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.ENVIRONMENT_REQUIRED") };
      }
    }
    catch
    {
    }

    return { valid: true, message: ""};
  }

  public BackupUserRangeIsValid(): { valid: boolean, message: string }
  {
    var control = this.contactMeForm.get('business.backupUserRange');
    var requiredValidation = Validators.required(control);

    if (!this.showValidationErrors)
      return { valid: true, message: ""};
    try {
      if (control.value == 0 || requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.ZERO_NOT_VALID") };
      }
    }
    catch
    {
    }

    return { valid: true, message: ""};
  }

  public InterestIsSelected(): { valid: boolean, message: string } {
    var migrationInterest = this.contactMeForm.get('business.migrationInterest');
    var backupInterest = this.contactMeForm.get('business.backupInterest');
    var cloudManagerInterest = this.contactMeForm.get('business.cloudManagerInterest');

    if (!this.showValidationErrors)
      return { valid: true, message: ""};

    if (migrationInterest.value || backupInterest.value || cloudManagerInterest.value) {
      return { valid: true, message: "" };
    } else {
      return { valid: false, message: this.translationService.instant("ERRORS.ONE_PRODUCT_SELECTION_REQUIRED") };
    }
  }

  public TermsAndConditionsSelected(): { valid: boolean, message: string } {
    var control = this.contactMeForm.get('termsAndCondtions');

    if (control.value)
    {
      return { valid: true, message: ''};
    }
    else
    {
      return { valid: false, message: ''}
    }
  }

  public handleCityChange(address: Address) {
    var city = this.contactMeForm.get('business.city');
    var state = this.contactMeForm.get('business.state');
    var postalCode = this.contactMeForm.get('business.postalCode');
    var country = this.contactMeForm.get('business.country');
    var displayCountry = this.contactMeForm.get('business.displayCountry');

    if (city != null) {
      var localityComponent = address.address_components.find(x => x.types.find(y => y == 'locality') != undefined);
      var townComponent = address.address_components.find(x => x.types.find(y => y == 'postal_town') != undefined);
      if (localityComponent) {
        city.setValue(localityComponent.long_name);
      } else if (townComponent) {
        city.setValue(townComponent.long_name);
      } else {
        city.setValue('');
      }
    }
    if (state != null) {
      var stateComponent = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
      if (stateComponent) {
        state.setValue(stateComponent.short_name);
      } else {
        state.setValue('');
      }
    }
    if (postalCode != null) {
      var postalCodeComponent = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);
      if (postalCodeComponent) {
        postalCode.setValue(postalCodeComponent.long_name);
      } else {
        postalCode.setValue('');
      }
    }
    if (country != null) {
      var countryComponent = address.address_components.find(x => x.types.find(y => y == 'country') != undefined);
      if (countryComponent) {
        country.setValue(countryComponent.short_name);
        displayCountry.setValue(countryComponent.long_name + ' (' + countryComponent.short_name + ')');
      } else {
        country.setValue('');
        displayCountry.setValue('');
      }
    }
  }

  public handleAddressChange(address: Address) {
    var businessAddress = this.contactMeForm.get('business.address');
    var businessCity = this.contactMeForm.get('business.city');
    var businessState = this.contactMeForm.get('business.state');
    var businessPostalCode = this.contactMeForm.get('business.postalCode');
    var businessCountry = this.contactMeForm.get('business.country');
    var businessDisplayCountry = this.contactMeForm.get('business.displayCountry');

    var streetNumber = address.address_components.find(x => x.types.find(y => y == 'street_number') != undefined);
    var street = address.address_components.find(x => x.types.find(y => y == 'route') != undefined)
    var address2 = address.address_components.find(x => x.types.find(y => y == 'subpremise') != undefined);
    var locality = address.address_components.find(x => x.types.find(y => y == 'locality') != undefined);
    var town = address.address_components.find(x => x.types.find(y => y == 'postal_town') != undefined);
    var state = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
    var postalcode = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);
    var country = address.address_components.find(x => x.types.find(y => y == 'country') != undefined);

    if (streetNumber && street && address2) {
      if (businessAddress != null) {
        businessAddress.setValue(streetNumber.long_name + " " + street.long_name + " #" + address2.long_name);
      }
    } else if (streetNumber && street) {
      if (businessAddress != null) {
        businessAddress.setValue(streetNumber.long_name + " " + street.long_name);
      }
    } else if (streetNumber) {
      if (businessAddress != null) {
        businessAddress.setValue(streetNumber.long_name);
      }
    } else if (street) {
      if (businessAddress != null) {
        businessAddress.setValue(street.long_name);
      }
    }
     else {
      businessAddress.setValue('');
    }

    if (locality) {
      businessCity.setValue(locality.long_name);
    } else if (town) {
      businessCity.setValue(town.long_name);
    } else {
      businessCity.setValue('');
    }

    if (state) {
      businessState.setValue(state.short_name);
    } else {
      businessState.setValue('');
    }

    if (postalcode) {
      businessPostalCode.setValue(postalcode.long_name);
    } else {
      businessPostalCode.setValue('');
    }

    if (country) {
      businessCountry.setValue(country.short_name);
      businessDisplayCountry.setValue(country.long_name + ' (' + country.short_name + ')');
    } else {
      businessCountry.setValue('');
      businessDisplayCountry.setValue('');
    }
  }

  public handleBusinessNameChange(address: Address) {
    var businessAddress = this.contactMeForm.get('business.address');
    var businessCity = this.contactMeForm.get('business.city');
    var businessState = this.contactMeForm.get('business.state');
    var businessPostalCode = this.contactMeForm.get('business.postalCode');
    var businessCountry = this.contactMeForm.get('business.country');
    var businessDisplayCountry = this.contactMeForm.get('business.displayCountry');

    var businessName = this.contactMeForm.get('business.companyName');

    var streetNumber = address.address_components.find(x => x.types.find(y => y == 'street_number') != undefined);
    var street = address.address_components.find(x => x.types.find(y => y == 'route') != undefined)
    var address2 = address.address_components.find(x => x.types.find(y => y == 'subpremise') != undefined);
    var locality = address.address_components.find(x => x.types.find(y => y == 'locality') != undefined);
    var town = address.address_components.find(x => x.types.find(y => y == 'postal_town') != undefined);
    var state = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
    var postalcode = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);
    var country = address.address_components.find(x => x.types.find(y => y == 'country') != undefined);

    if (businessName != null) {
      businessName.setValue(address.name);
    }

    if (streetNumber && street && address2) {
      if (businessAddress != null) {
        businessAddress.setValue(streetNumber.long_name + " " + street.long_name + " #" + address2.long_name);
      }
    } else if (streetNumber && street) {
      if (businessAddress != null) {
        businessAddress.setValue(streetNumber.long_name + " " + street.long_name);
      }
    } else if (streetNumber) {
      if (businessAddress != null) {
        businessAddress.setValue(streetNumber.long_name);
      }
    } else if (street) {
      if (businessAddress != null) {
        businessAddress.setValue(street.long_name);
      }
    }
     else {
      businessAddress.setValue('');
    }

    if (locality) {
      businessCity.setValue(locality.long_name);
    } else if (town) {
      businessCity.setValue(town.long_name);
    } else {
      businessCity.setValue('');
    }

    if (state) {
      businessState.setValue(state.short_name);
    } else {
      businessState.setValue('');
    }

    if (postalcode) {
      businessPostalCode.setValue(postalcode.long_name);
    } else {
      businessPostalCode.setValue('');
    }

    if (country) {
      businessCountry.setValue(country.short_name);
      businessDisplayCountry.setValue(country.long_name + ' (' + country.short_name + ')');
    } else {
      businessCountry.setValue('');
      businessDisplayCountry.setValue('');
    }
  }
}

function ValidateInterest(control: AbstractControl): { [key: string]: boolean } | null {
  const validateMigrationInterest = ValidateMigrationInterest(control);
  const validateBackupInterest = ValidateBackupInterest(control);

  const migrationInterest = control.get('migrationInterest');
  const backupInterest = control.get('backupInterest');
  const cloudManagerInterest = control.get('cloudManagerInterest');

  if (migrationInterest != null && migrationInterest.value && validateMigrationInterest != null) {
    return validateMigrationInterest;
  }
  if (backupInterest != null && backupInterest.value && validateBackupInterest != null) {
    return validateBackupInterest;
  }

  if ((migrationInterest != null && migrationInterest.value && validateMigrationInterest == null) || (backupInterest != null && backupInterest.value && validateBackupInterest == null) || (cloudManagerInterest != null && cloudManagerInterest.value)) {
    return null;
  }
  else {
    return { 'RequiredOneInterest': true };
  }
};

const Options = {
  EmailEnvironment: ['EMAILENVIRONMENT.ONE', 'EMAILENVIRONMENT.TWO', 'EMAILENVIRONMENT.THREE', 'EMAILENVIRONMENT.FOUR', 'EMAILENVIRONMENT.FIVE', 'EMAILENVIRONMENT.SIX', 'EMAILENVIRONMENT.SEVEN'],
  MailboxRange: ['MAILBOXRANGE.ONE', 'MAILBOXRANGE.TWO', 'MAILBOXRANGE.THREE', 'MAILBOXRANGE.FOUR', 'MAILBOXRANGE.FIVE', 'MAILBOXRANGE.SIX', 'MAILBOXRANGE.SEVEN', 'MAILBOXRANGE.EIGHT'],
  Api: {
    types: ['establishment']
  },
  AddressApi: {
    types: ['address']
  },
  CityApi: {
    types: ['(cities)']
  },
  CountryApi: {
    types: ['country']
  },
}

function ValidateMigrationInterest(control: AbstractControl): { [key: string]: boolean } | null {
  const migrationInterest = control.get('migrationInterest');
  const emailMigrationRange = control.get('emailMigrationRange');
  const emailEnvironment = control.get('emailEnvironment');

  if (!migrationInterest) {
    return null;
  }
  else {
    if (emailMigrationRange != null && !ValidateEmailMigrationRange(emailMigrationRange) && emailEnvironment != null && !ValidateEmailEnvironment(emailEnvironment)) {
      return { 'InvalidRangeAndEnvironment': true };
    }
    else if (emailMigrationRange == null || !ValidateEmailMigrationRange(emailMigrationRange)) {
      return { 'InvalidRange': true };
    }
    else if (emailEnvironment == null || !ValidateEmailEnvironment(emailEnvironment)) {
      return { 'InvalidEnvironment': true };
    }

    return null;
  }
};

const EmailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

function ValidateBackupInterest(control: AbstractControl): { [key: string]: boolean } | null {
  const backupInterest = control.get('backupInterest');
  const backupUserRange = control.get('backupUserRange');

  if (!backupInterest) {
    return null;
  }
  else {
    if (backupUserRange == null || !ValidateBackupUserRange(backupUserRange)) {
      return { 'InvalidRange': true };
    }

    return null;
  }
};

function ValidateEmailMigrationRange(range: AbstractControl): boolean {
  if (range == null) {
    return false;
  }
  else {
    try {
      return Options.MailboxRange[range.value] != null
    }
    catch
    {
      return false;
    }
  }
};

function ValidateBackupUserRange(range: AbstractControl): boolean {
  if (range == null) {
    return false;
  }
  else {
    try {
      return Options.MailboxRange[range.value] != null;
    }
    catch
    {
      return false;
    }
  }
};

function ValidateEmailEnvironment(environment: AbstractControl): boolean {
  if (environment == null) {
    return false;
  }
  else {
    return Options.EmailEnvironment[environment.value] != null;
  }
};

function ValidateEmail(email: AbstractControl): { [key: string]: boolean } | null {


  if (!EmailRegex.test(email.value)) {
    return { 'InvalidEmail': true }
  }

  return null;
}
