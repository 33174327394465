<nav class="navbar fixed-top sk-header sk-reg">
  <div class="container sk-reg">
    <div class="sk-logo sk-reg p-0">
      <a href="https://www.skykick.com/" id="sk-registerNoGenPageAttributePartnerLogo">
        <img id="logo" src="../assets/images/skykick-logo.svg">
      </a>
    </div>
  </div>
</nav>
  
  
  <div class="container sk-content sk-reg">
    <h1 class="display-3 text-center pt-200" translate="REGISTER.TITLE"></h1>
    <h3 class="text-center m-3 font-weight-normal pb-200" translate="REGISTER.SUBTITLE"></h3>
    <div class="alert alert-dismissible sk-alert-icon-24 alert-danger d-flex mb-100" *ngIf="bannerError.show">
      <i class="material-icons md-22 pull-xs-left">error_outline</i>
      <div class="ml-100">
        <span class="sk-alert-text" [innerHTML]="bannerError.message"></span>
      </div>
      <button type="button" class="close" (click)="closePlatformError()">
        <i class="material-icons md-18">close</i>
      </button>
    </div>
  <div class="container sk-reg card sk-card">
    <form [formGroup]="registrationForm" class="px-lg-5 mb-5" (ngSubmit)="onSubmit()">
      <div class="row col-sm-12 my-4">
        <h3 translate="REGISTER.ACCOUNT"></h3>
      </div>
      <div formGroupName="contact" class="mb-3">
        <div class="row form-group">
          <div class="col-md-12 pb-3 pb-md-0 my-2" [class.has-danger]="this.showValidationErrors && !this.fullNameError.valid">
            <label translate="REGISTER.CONTACT_NAME"></label>
            <input id="sk-contactname" formControlName="fullName" type="text" class="form-control" maxlength="50" tabindex=1>
            <small class="form-control-feedback" [hidden]="!this.showValidationErrors || this.fullNameError.valid">{{this.fullNameError.message}}</small>
          </div>
          <div class="col-md-6 my-2" [class.has-danger]="this.showValidationErrors && !this.emailError.valid">
            <label class="control-label" translate="REGISTER.BUSINESS_EMAIL"></label>
            <input id="sk-email" formControlName="email" type="text" class="form-control" maxlength="50" tabindex=2>
            <small class="form-control-feedback" [hidden]="!this.showValidationErrors || this.emailError.valid">{{this.emailError.message}}</small>
          </div>
          <div class="col-md-6 my-2" [class.has-danger]="this.showValidationErrors && !this.passwordError.valid">
            <label class="control-label" translate="REGISTER.PASSWORD"></label>
            <button type="button" class="btn sk-btn-inline-icon sk-password-toggle float-right" (click)="this.passwordToggle = !this.passwordToggle;" onclick="document.getElementById('sk-password').getAttribute('type')==='password' ? document.getElementById('sk-password').setAttribute('type', 'text') : document.getElementById('sk-password').setAttribute('type', 'password');">
              <i class="material-icons md-18" [hidden]="this.passwordToggle">visibility</i>
              <i class="material-icons md-18" [hidden]="!this.passwordToggle">visibility_off</i>
            </button>
            <input id="sk-password" formControlName="password" type="password" class="form-control" maxlength="50" tabindex=3>
            <small class="text-muted" translate="REGISTER.PASSWORD_REQUIREMENT"></small>
            <small class="form-control-feedback" [hidden]="!this.showValidationErrors || this.passwordError.valid">{{this.passwordError.message}}</small>
          </div>
        </div>
      </div>
      <div class="row col-sm-12 my-4">
        <h3 translate="REGISTER.BUSINESS_PROFILE"></h3>
      </div>
      <div formGroupName="business" class="mb-3">
        <div class="row form-group"> 
          <div class="col-md-12 pb-3 pb-md-0 my-2" [class.has-danger]="this.showValidationErrors && !this.companyNameError.valid">
            <label translate="REGISTER.BUSINESS_NAME"></label>
            <input id="sk-companyname" formControlName="companyName" type="text" ngx-google-places-autocomplete
            [options]='options.Api' #placesRef="ngx-places" (onAddressChange)="handleBusinessNameChange($event)" placeholder="" class="form-control" maxlength="100" tabindex=4>
            <small class="form-control-feedback" [hidden]="!this.showValidationErrors || this.companyNameError.valid">{{this.companyNameError.message}}</small>
          </div>

          <div class="col-md-12 pb-3 pb-md-0 my-2" [class.has-danger]="this.showValidationErrors && !this.addressError.valid">
            <label translate="CONTACT_ME.BUSINESS_PROFILE.ADDRESS"></label>
            <input id="sk-address" formControlName="address" type="text" class="form-control" ngx-google-places-autocomplete [options]='options.AddressApi' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" placeholder="" maxlength="50" tabindex=5>
              <small class="form-control-feedback" [hidden]="this.showValidationErrors && this.addressError.valid">{{this.addressError.message}}</small>
          </div>
            <div class="col-md-4 pb-3 pb-md-0" [class.has-danger]="this.showValidationErrors && !this.cityError.valid">
              <label translate="CONTACT_ME.BUSINESS_PROFILE.CITY"></label>
              <input id="sk-city" formControlName="city" type="text" class="form-control" ngx-google-places-autocomplete
                [options]='options.CityApi' #placesRef="ngx-places" (onAddressChange)="handleCityChange($event)" placeholder=""  maxlength="50" tabindex=6>
                <small class="form-control-feedback" [hidden]="this.showValidationErrors && this.cityError.valid">{{this.cityError.message}}</small>
            </div>
            <div class="col-md-4 pb-3">
              <label translate="CONTACT_ME.BUSINESS_PROFILE.STATE"></label>
              <input id="sk-state" formControlName="state" type="text" class="form-control" placeholder=""  maxlength="50" disabled>
            </div>
            <div class="col-md-4" [class.has-danger]="this.showValidationErrors && !this.countryError.valid">
              <label translate="CONTACT_ME.BUSINESS_PROFILE.COUNTRY"></label>
              <input id="sk-displayCountry" formControlName="displayCountry" type="text" class="form-control" placeholder=""  maxlength="50" disabled>
              <small class="form-control-feedback" [hidden]="this.showValidationErrors && this.countryError.valid">{{this.countryError.message}}</small>
            </div>
          <input id="sk-country" formControlName="country" type="text" class="form-control" placeholder=""  maxlength="50" hidden>
          <input id="sk-postalCode" formControlName="postalCode" type="text" class="form-control" placeholder=""  maxlength="50" hidden>

          <div class="col-md-6 my-2" [class.has-danger]="this.showValidationErrors && !this.phoneNumberError.valid">
            <label class="control-label" translate="REGISTER.BUSINESS_PHONE"></label>
            <input id="sk-phone" formControlName="phoneNumber" type="tel" class="form-control" maxlength="25" tabindex=7>
            <small class="form-control-feedback" [hidden]="!this.showValidationErrors || this.phoneNumberError.valid">{{this.phoneNumberError.message}}</small>
          </div>
          <div class="col-md-6 my-2" [class.has-danger]="">
            <label class="control-label" translate="REGISTER.MOBILE_PHONE"></label>
            <small class="sk-label-text text-muted float-right" translate="COMMON.OPTIONAL"></small>
            <input id="sk-mobile" formControlName="mobilePhoneNumber" type="tel" class="form-control" maxlength="25" tabindex=8>
            <small class="form-control-feedback" [hidden]="!this.showValidationErrors || this.mobilePhoneNumberError.valid">{{this.mobilePhoneNumberError.message}}</small>
          </div>

          <div class="col-md-12 pb-3 pb-md-0 my-2 mb-150" [class.has-danger]="this.showValidationErrors && !this.roleError.valid">
            <label class="control-label" translate="REGISTER.WHAT_IS_YOUR_ROLE"></label>
            <div class="row">
              <div class="col-md-12 btn-group d-flex btn-group-toggle" data-toggle="buttons" *ngIf="!this.isMobile">
                <label class="btn btn-outline-secondary" *ngFor="let option of this.options.Roles; let i = index" [ngClass]="{'active': RoleIsActive(i)}">
                  <input id="{{ 'sk-role' + i }}" name="roleoptions" type="radio" (click)="SetRole(i);" tabindex=9>
                  {{option | translate}}
                </label>
              </div>
              <div class="col-md-12 btn-group-vertical d-flex btn-group-toggle" *ngIf="this.isMobile" data-toggle="buttons">
                <label class="btn btn-outline-secondary" *ngFor="let option of this.options.Roles; let i = index" [ngClass]="{'active': RoleIsActive(i)}">
                  <input id="{{ 'sk-role' + i }}" name="roleoptions" type="radio" (click)="SetRole(i);" tabindex=10>
                  {{option | translate}}
                </label>
              </div>
            </div>
            <small class="d-flex align-items-center mr-100">
              <i [hidden]="!this.showValidationErrors || this.roleError.valid" class="material-icons md-22 icon-danger">error</i> <span [hidden]="!this.showValidationErrors || this.roleError.valid" class="text-danger ml-50">{{this.roleError.message}}</span>
            </small>
          </div>

          <div class="col-md-12 pb-3 pb-md-0 my-2 mb-150">
            <label class="control-label" translate="REGISTER.HOW_MANY_EMPLOYEES"></label>
            <div class="d-flex">
              <div class="d-flex flex-fill flex-column">
                <input type="range" id="sk-employees" formControlName="employees" class="custom-range" min="0"
                  max="{{this.options.Employees.length - 1}}" value="0" tabindex=11>
                <div class="sk-range-key d-sm-none d-md-flex" style="display:none">
                  <div class="sk-tick-mark" *ngFor="let option of this.options.Employees">
                    <div class="tick-label" translate="{{option}}"></div>
                  </div>
                </div>
              </div>
              <div class="sk-range-value" translate="{{this.options.Employees[this.registrationForm.value.business.employees]}}">}</div>
            </div>
          </div>
          <div class="col-md-12 pb-3 pb-md-0 my-2 mb-150">
            <label class="control-label" translate="REGISTER.HOW_MANY_SEATS"></label>
            <div class="d-flex">
              <div class="d-flex flex-fill flex-column">
                <input type="range" id="sk-seats" formControlName="seats" class="custom-range" min="0"
                  max="{{this.options.Seats.length - 1}}" value="0" tabindex=12>
                <div class="sk-range-key d-sm-none d-md-flex" style="display:none">
                  <div class="sk-tick-mark" *ngFor="let option of this.options.Seats">
                    <div class="tick-label" translate="{{option}}"></div>
                  </div>
                </div>
              </div>
              <div class="sk-range-value" translate="{{this.options.Seats[this.registrationForm.value.business.seats]}}">}</div>
            </div>
          </div>

          <div class="col-md-12 pb-3 pb-md-0 my-2 mb-150" [class.has-danger]="this.showValidationErrors && !this.experienceError.valid">
            <label class="control-label" translate="REGISTER.WHAT_IS_YOUR_EXPERIENCE"></label>
            <small class="text-muted ml-50" translate="REGISTER.EXPERIENCE_CALCULATION"></small>
            <div class="row">
              <div class="col-md-12 btn-group d-flex btn-group-toggle" data-toggle="buttons" *ngIf="!this.isMobile">
                <label class="btn btn-outline-secondary" *ngFor="let option of this.options.Experience; let i = index" [ngClass]="{'active': ExperienceIsActive(i)}">
                  <input id="{{ 'sk-experience' + i }}" name="experienceoptions" type="radio" class="btn btn-outline-secondary" (click)="SetExperience(i);" tabindex=13>
                  {{option | translate}}
                </label>
              </div>
              <div class="col-md-12 btn-group-vertical d-flex btn-group-toggle" data-toggle="buttons" *ngIf="this.isMobile">
                <label class="btn btn-outline-secondary" *ngFor="let option of this.options.Experience; let i = index" [ngClass]="{'active': ExperienceIsActive(i)}">
                  <input id="{{ 'sk-experience' + i }}" name="experienceoptions" type="radio" class="btn btn-outline-secondary" (click)="SetExperience(i);" tabindex=14>
                  {{option | translate}}
                </label>
              </div>
            </div>
            <small class="d-flex align-items-center mr-100">
              <i [hidden]="!this.showValidationErrors || this.experienceError.valid" class="material-icons md-22 icon-danger">error</i> <span [hidden]="!this.showValidationErrors || this.experienceError.valid" class="text-danger ml-50">{{this.experienceError.message}}</span>
            </small>
          </div>

          <div class="col-md-12 pb-3 pb-md-0 my-2 mb-150">
            <label class="control-label" translate="REGISTER.HOW_MANY_EXPECTED_SEATS"></label>
            <div class="d-flex">
              <div class="d-flex flex-fill flex-column">
                <input type="range" id="sk-expected-seats" formControlName="expectedSeats" class="custom-range" min="0"
                  max="{{this.options.ExpectedSeats.length - 1}}" value="0" tabindex=15>
                <div class="sk-range-key d-sm-none d-md-flex" style="display:none">
                  <div class="sk-tick-mark" *ngFor="let option of this.options.ExpectedSeats">
                    <div class="tick-label" translate="{{option}}"></div>
                  </div>
                </div>
              </div>
              <div class="sk-range-value" translate="{{this.options.ExpectedSeats[this.registrationForm.value.business.expectedSeats]}}">}</div>
            </div>
          </div>

          <div class="col-md-6 my-2" [class.has-danger]="this.showValidationErrors && !this.interestError.valid">
            <label class="control-label" translate="REGISTER.WHAT_ARE_YOU_INTERESTED"></label>
            <div class="btn-group btn-block" ngbDropdown>
              <button type="button" class="btn btn-outline dropdown-toggle btn-block text-xs-left" id="sk-interest" data-toggle="dropdown" aria-haspopup="true" ngbDropdownToggle translate="{{this.options.Interest[this.registrationForm.value.business.interest]}}" tabindex=16></button>
              <div class="dropdown-menu btn-block" x-placement="bottom-start" ngbDropdownMenu>
                <a class="dropdown-item sk-dropdown-element" ngbDropdownItem href="javascript:void(0);" role="button" aria-haspopup="true" aria-expanded="false" *ngFor="let option of this.options.Interest; let i = index" id="{{ 'sk-interest' + i }}" (click)="SetInterest(i);" translate="{{option}}" ></a>
              </div>
            </div>
            <small class="d-flex align-items-center mr-100">
              <i [hidden]="!this.showValidationErrors || this.interestError.valid" class="material-icons md-22 icon-danger">error</i> <span [hidden]="!this.showValidationErrors || this.interestError.valid" class="text-danger ml-50">{{this.interestError.message}}</span>
            </small>
          </div>
          <div class="col-md-6 my-2" [class.has-danger]="this.showValidationErrors && !this.hearAboutUsError.valid">
            <label class="control-label" translate="REGISTER.HOW_DID_YOU_HEAR"></label>
            <div class="btn-group btn-block" ngbDropdown>
              <button ngbDropdownToggle type="button" class="btn btn-outline dropdown-toggle btn-block text-xs-left" id="sk-hearaboutus" data-toggle="dropdown" aria-haspopup="true" translate="{{this.options.HearAboutUs[this.registrationForm.value.business.hearAboutUs]}}" tabindex=17></button>
              <div ngbDropdownMenu class="dropdown-menu btn-block" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 34px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a ngbDropdownItem class="dropdown-item sk-dropdown-element" href="javascript:void(0);" role="button" aria-haspopup="true" aria-expanded="false" *ngFor="let option of this.options.HearAboutUs; let i = index" id="{{ 'sk-hearaboutus' + i }}" (click)="SetHearAboutUs(i);" translate="{{option}}"></a>
              </div>
            </div>
            <small class="d-flex align-items-center mr-100">
              <i [hidden]="!this.showValidationErrors || this.hearAboutUsError.valid" class="material-icons md-22 icon-danger">error</i> <span [hidden]="!this.showValidationErrors || this.hearAboutUsError.valid" class="text-danger ml-50">{{this.hearAboutUsError.message}}</span>
            </small>
          </div>
        </div>
      </div>
      <div class="form-group mt-5 my-2" [class.has-danger]="this.showValidationErrors && !this.termsAndConditionsError.valid">
        <div class="custom-control custom-checkbox sk-checkbox">
          <input formControlName="termsAndCondtions" type="checkbox" id="tandcchkbx"
            class="custom-control-input" tabindex=18/>
          <label class="custom-control-label" for="tandcchkbx"><span [innerHTML]="'COMMON.TERMS_AND_CONDITIONS' | translate:{ termsUrl: 'https://www.skykick.com/partner-terms-conditions/', privacyUrl: 'https://www.skykick.com/privacy-policy' }"></span></label>
        </div>
      </div>
      <button id="sk-submit" [disabled]="btnDisabled" class="btn btn-lg btn-primary btn-block my-200" type="submit" translate="REGISTER.SUBMIT" tabindex=19></button>
    </form>
  </div>
  <app-footer></app-footer>
  </div>