import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RegistrationRequest } from '../models/RegistrationRequest';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RegistrationService {
    constructor(http) {
        this.http = http;
    }
    register(request) {
        return this.http.post(`https://${environment.prefix}papi.skykick.com/partner/register`, request);
    }
    ConvertRegistrationForm(form) {
        let registrationRequest = new RegistrationRequest();
        var parsedName = this.ParseFullName(form.contact.fullName);
        registrationRequest.firstName = parsedName.firstName;
        registrationRequest.lastName = parsedName.lastName;
        registrationRequest.email = form.contact.email;
        registrationRequest.password = form.contact.password;
        registrationRequest.companyName = form.business.companyName;
        registrationRequest.phone = form.business.phoneNumber;
        registrationRequest.mobilePhone = form.business.mobilePhoneNumber;
        registrationRequest.role = form.business.role != undefined ? form.business.role : null;
        registrationRequest.numberOfEmployees = form.business.employees != undefined ? form.business.employees : null;
        registrationRequest.numberOfO365Licenses = form.business.seats != undefined ? form.business.seats : null;
        registrationRequest.experience = form.business.experience != undefined ? form.business.experience : null;
        registrationRequest.expectedO365Seats_12Months = form.business.expectedSeats != undefined ? this.ParseExpectedSeats(form.business.expectedSeats) : null;
        registrationRequest.interest = form.business.interest != undefined ? form.business.interest : null;
        registrationRequest.hearAboutUs = form.business.hearAboutUs != undefined ? form.business.hearAboutUs : null;
        registrationRequest.address1 = form.business.address != undefined ? form.business.address : null;
        registrationRequest.city = form.business.city != undefined ? form.business.city : null;
        registrationRequest.state = form.business.state != undefined ? form.business.state : null;
        registrationRequest.country = form.business.displayCountry != undefined ? form.business.displayCountry : null;
        registrationRequest.countryCode = form.business.country != undefined ? form.business.country : null;
        registrationRequest.postalCode = form.business.postalCode != undefined ? form.business.postalCode : null;
        return registrationRequest;
    }
    ParseFullName(fullName) {
        let splitted = fullName.split(" ");
        if (splitted.length != 2) {
            return {
                firstName: fullName,
                lastName: ""
            };
        }
        return {
            firstName: splitted[0],
            lastName: splitted[1],
        };
    }
    ParseExpectedSeats(seats) {
        // use upper limit of range
        let ExpectedSeats;
        (function (ExpectedSeats) {
            ExpectedSeats[ExpectedSeats["ZeroToFiveHundred"] = 500] = "ZeroToFiveHundred";
            ExpectedSeats[ExpectedSeats["FiveHundredOneToOneThousand"] = 1000] = "FiveHundredOneToOneThousand";
            ExpectedSeats[ExpectedSeats["OneThousandOneToFiveThousand"] = 5000] = "OneThousandOneToFiveThousand";
            ExpectedSeats[ExpectedSeats["FiveThousandOneToTenThousand"] = 10000] = "FiveThousandOneToTenThousand";
            ExpectedSeats[ExpectedSeats["TenThousandPlus"] = 10001] = "TenThousandPlus";
        })(ExpectedSeats || (ExpectedSeats = {}));
        switch (seats) {
            case 0:
                return +ExpectedSeats.ZeroToFiveHundred;
            case 1:
                return +ExpectedSeats.FiveHundredOneToOneThousand;
            case 2:
                return +ExpectedSeats.OneThousandOneToFiveThousand;
            case 3:
                return +ExpectedSeats.FiveThousandOneToTenThousand;
            case 4:
                return +ExpectedSeats.TenThousandPlus;
            default:
                return +ExpectedSeats.ZeroToFiveHundred;
        }
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.HttpClient)); }, token: RegistrationService, providedIn: "root" });
